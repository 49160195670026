$.getScript("modules/ieDetect/module_ieDetect.js");

// components
if ($('.c-linkScrollDown').length) {
    $('.c-linkScrollDown').on('click', function(event) {
        event.preventDefault();
        goToSection__scroll($('main'), 0);
    });
}
if ($('.l-fixed-faq').length) {
    $(window).on('load', function () {
        setTimeout(function(){
            $('.l-fixed-faq').addClass('is-active');
        }, 3000);
    });
    $(window).on('scroll', function () {
        $('.l-fixed-faq').addClass('is-active');
    });

    var page = window.location.pathname;
    var finalPage = page.split('/');

    if(finalPage.pop() === 'perguntas-frequentes') {
        console.log('teste');
        $('.l-fixed-faq .c-btn').on('click', function(e) {
            e.preventDefault();
            var finalDestiny = $(this).attr('href').split('#')[1];
            finalDestiny = '#' + finalDestiny;
            goToSection__scroll(finalDestiny, 0, 0, 700, 10);
        });
    }
}
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}
if ($('.s-grid__numbers').length) {
    function animateValue(id, start, end, duration) {
        var range = end - start;
        var current = start;
        var increment = end > start? 1 : -1;
        var stepTime = Math.abs(Math.floor(duration / range));
        var obj = document.getElementById(id);
        var timer = setInterval(function() {
            current += increment;
            obj.innerHTML = current;
            if (current == end) {
                clearInterval(timer);
            }
        }, stepTime);
    }
    var calledAnimate = false;
    function callAnimates() {
        if (calledAnimate == false) {
            animateValue("number_litros", 0, 10, 200);
            animateValue("number_flaconetes", 0, 15, 300);
            animateValue("number_comprimidos", 0, 90, 1800);
            animateValue("number_capsulas", 0, 100, 2000);
        } else {
            // nothing here
        }
    }
    $(window).on('scroll', function() {
        var offSetGridNumbers = $('.s-grid__numbers').offset();
        var scrollEfetuado = $(window).scrollTop();
        var viewPortHeight = $(window).height();
        var startAnimate = offSetGridNumbers.top - viewPortHeight + 100;
        if (scrollEfetuado >= startAnimate) {
            callAnimates();
            calledAnimate = true;
        };
    });
};

// nav
if ($('.defaultNav').length) {
    var scroll_init = 0;
    var scrollEfetuado = $(window).scrollTop();

    $(window).on('scroll', function(event) {
        var scroll_body = $(this).scrollTop();
        var nav = $('nav');

        /*
            Aplica a classe que contem o estilo
            primário para o nav quando a pagina
            executar o evento scroll
        */
        if (scroll_body > 99) {
            nav.addClass("defaultNav--scroll");
        } else {
            nav.removeClass("defaultNav--scroll");
        }

        /*
            Quando a página rolar 600px pra baixo o nav
            desaparece e recebe a classe "middle" para
            identificar o estado.
        */
        if (scroll_body > 450) {
            nav.addClass("defaultNav--hide");
            nav.addClass("defaultNav--middle");
        } else {
            nav.removeClass("defaultNav--hide");
            nav.removeClass("defaultNav--middle");
        }

        /*
            verifica se o scroll da página foi
            pra cima, caso sim faz o menu aparecer.
        */
        if (scroll_body < scroll_init) {
            nav.removeClass("defaultNav--hide");
        }

        // var referencia
        scroll_init = scroll_body;
    });
    $(window).on('load', function() {
        if (scrollEfetuado > 0) {
            $('.defaultNav').addClass('defaultNav--scroll');
            $('.defaultNav').addClass('defaultNav--middle');
            $('.defaultNav').removeClass('defaultNav--hide');
        }
    });

    // open/close hamburguer menu
    $('.c-hamburguer').on('click', function() {
        $(this).toggleClass('is-active');
        $('.defaultNav__contentNav').toggleClass('is-active');
        $('.defaultNav__maskMenu').toggleClass('is-active');
        $('.u-maxRow').toggleClass('nav__is-active');
        $('body').toggleClass('u-overflowBlocked');
    });

    // close hamburguer menu
    $('.defaultNav__maskMenu').on('click', function() {
        $('.c-hamburguer').removeClass('is-active');
        $('.defaultNav__contentNav').removeClass('is-active');
        $('.defaultNav__maskMenu').removeClass('is-active');
        $('.u-maxRow').removeClass('nav__is-active');
        $('body').removeClass('u-overflowBlocked');
    });
}